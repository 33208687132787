import { renderPage,getCachedResourceJson } from '/src/resouces/js/commonRequest.js';

// Define la interfaz para el perfil de tecnología
interface Tecnologia {
  id: number;
  nombre: string;
  // Agregar otros campos según la estructura de los datos recibidos
}

// Función para manejar errores del servidor
const manejarErrorServidor = async () => {
  try {
    await renderPage('/pages/ServerError.hbs', { container: {} }); // Corregido
  } catch (error) {
    console.error('Error al renderizar la página de error del servidor:', error);
    // Aquí podrías mostrar un mensaje de error al usuario si la renderización falla
    alert('Se produjo un error en el servidor. Por favor, inténtelo de nuevo más tarde.'); // Mensaje amigable para el usuario
  }
};

// Función para obtener los datos de las tecnologías usando fetch
const obtenerTecnologia = async (): Promise<Tecnologia[]> => {
  try {
    // Realizar la solicitud a la API usando fetch
    const response = await getCachedResourceJson('https://lfgarcia.com/tecnologias'); // Cambia a HTTPS


    // Verificar si los datos obtenidos son un array
    if (Array.isArray(response)) {
      // Procesar cada tecnología en los datos obtenidos
      const tecnologia = response.map((tecno: any) => ({
        id: Number(tecno.id), // Convertir a número por seguridad
        nombre: tecno.nombre ?? '' // Default empty string if undefined
        // Agregar otros campos según la estructura de los datos recibidos
      }));

      return tecnologia;
    } else {
      console.error('Los datos obtenidos no son un array');
      return [];
    }
  } catch (error) {
    console.error('Error al obtener los datos de la API:', error);
    // Manejar el error del servidor
    await manejarErrorServidor();
    return [];
  }
};

export default obtenerTecnologia;
