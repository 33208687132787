import Handlebars from 'handlebars';
import { routes } from '../js/routes';
import { Buffer } from 'buffer-es6';
global.Buffer = Buffer;


export async function getCachedResource(url)
{
    const offlineId = `CACHED_${url}`;
    const offlineResource = localStorage.getItem(offlineId);

    if (offlineResource)
        return offlineResource;

    const res = await fetch(url);
    const content = await res.text();

    sessionStorage.setItem(offlineId, content);
    return content;
}

export async function renderPage(url, data = {}) {
  try {
    const res = await fetch(url);
    if (!res.ok) {
      throw new Error(`HTTP error! status: ${res.status}`);
    }

    const content = await getCachedResource(url);
    console.log('Fetched content:', content); // Log content for debugging

    const compiledTemplate = Handlebars.compile(content);
    console.log('Compiled template:', compiledTemplate); // Log compiled template

    // Esperar hasta que el elemento 'header' esté disponible
    const waitForElement = (selector) => {
      return new Promise((resolve) => {
        const element = document.querySelector(selector);
        if (element) {
          resolve(element);
        } else {
          const observer = new MutationObserver((mutations, me) => {
            const element = document.querySelector(selector);
            if (element) {
              me.disconnect();
              resolve(element);
            }
          });
          observer.observe(document, { childList: true, subtree: true });
        }
      });
    };

    const header = await waitForElement('header');
    console.log('Header element:', header); // Log header element

    // Insertar el contenido compilado al principio del elemento 'header'
    header.insertAdjacentHTML('afterbegin', compiledTemplate(data));

  } catch (error) {
    console.error('Error rendering Handlebars template:', error);
  }
}



export async function renderHomeHeader(url, data = {}) {
  try {
 
    const content = await getCachedResource(url);
    const compiledTemplate = Handlebars.compile(content);
    const html = compiledTemplate(data) 
    document.getElementById('portfolio').innerHTML=html
  } catch (error) {
    console.error('Error rendering Handlebars template:', error);
  }
}

export async function renderHomeMain(url, data = {}) {
  try {

    const content = await getCachedResource(url);
    const compiledTemplate = Handlebars.compile(content);
    document.querySelector('main').innerHTML = compiledTemplate(data);
    
  } catch (error) {
    console.error('Error rendering Handlebars template main:', error);

  }
}

/*
export async function renderHomeProyects(url, data = {}) {
  try {
    const res = await fetch(url);
    if (!res.ok) {
      throw new Error(`HTTP error! status: ${res.status}`);
    }
    const content = await getCachedResource(url);
    const compiledTemplate = Handlebars.compile(content);
    const containerHTML = compiledTemplate(data);
    const containerfinal = document.getElementById('proyectsAll')
    containerfinal.innerHTML+=(containerHTML)
  } catch (error) {
    console.error('Error rendering Handlebars template:', error);
  }
}

*/
export async function renderHomeProyects(url, data = {}) {
  try {
 
    const content = await getCachedResource(url);
    const compiledTemplate = Handlebars.compile(content);

    // Esperar hasta que el elemento esté disponible
    const waitForElement = (selector) => {
      return new Promise((resolve) => {
        const element = document.querySelector(selector);
        if (element) {
          resolve(element);
        } else {
          const observer = new MutationObserver((mutations, me) => {
            const element = document.querySelector(selector);
            if (element) {
              me.disconnect();
              resolve(element);
            }
          });
          observer.observe(document, { childList: true, subtree: true });
        }
      });
    };

    const container = await waitForElement('#proyectsAll');

    // Crear un contenedor temporal para almacenar el contenido compilado
    const tempContainer = document.createElement('div');
    tempContainer.innerHTML = compiledTemplate(data);

    // Agregar cada hijo del contenedor temporal al contenedor real
    Array.from(tempContainer.children).forEach(child => {
      container.appendChild(child);
    });

  } catch (error) {
    console.error('Error rendering Handlebars template main:', error);
  }
}




export async function renderFooter(url, data = {}) {
  try {
   
    const content = await getCachedResource(url);
    const compiledTemplate = Handlebars.compile(content);
    document.querySelector('#root footer').innerHTML = compiledTemplate(data);
  } catch (error) {
    console.error('Error rendering Handlebars template:', error);
  }
}

// Esta función realiza una solicitud GET a la API y devuelve los datos en formato JSON
export async function getApi(url) {
  const response = await fetch(url);
  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }
  return await response.json();
}

// Esta función navega a una nueva URL y carga la página correspondiente
export async function navigate(to) {
  history.pushState({}, '', to);
  await loadPage();
}

// Esta función carga la página basada en la ruta actual
export async function loadPage() {
  const currentRoute = location.pathname;
  const pageContent = routes[currentRoute];
  if (pageContent) {
    // Si existe una función asociada a la ruta, llámala
    await pageContent();
  }
}



export async function auth(metodo, url, body) {
  const endpoint = `https://lfgarcia.com/${url}`;
  const offlineId = `CACHED_${url}`;

  // Prepara los parámetros de la solicitud
  const config = {
    method: metodo,
    headers: {
      'Content-Type': 'application/json'    },
    body: JSON.stringify(body)
  };

  // Verifica si el recurso ya está en caché
  const cachedResource = sessionStorage.getItem(offlineId);

  if (cachedResource) {
    try {
      return JSON.parse(cachedResource); // Intenta parsear el recurso en caché
    } catch (e) {
      console.error('Error parsing cached resource:', e);
      // Si hay un error al parsear, continúa con la solicitud de red
    }
  }

  try {
    // Realiza la solicitud si no está en caché o si hubo un error al parsear
    const response = await fetch(endpoint, config);

    if (!response.ok) {
      throw new Error(`Error al realizar la solicitud. Código de estado: ${response.status}`);
    }

    // Verifica si la respuesta tiene contenido
    const contentType = response.headers.get('Content-Type');
    if (contentType && contentType.includes('application/json')) {
      const data = await response.json();
      
      // Guarda la respuesta en sessionStorage si es un JSON válido
      sessionStorage.setItem(offlineId, JSON.stringify(data));

      return data;
    } else {
      throw new Error('La respuesta no es JSON');
    }
  } catch (error) {
    throw new Error(`Error al realizar la solicitud: ${error.message}`);
  }
}
     



export async function requestMain(metodo, url, body) {
  const endpoint = `https://lfgarcia.com/${url}`;
  
  // Prepara los parámetros de la solicitud
  const config = {
    method: metodo,
    headers: {
      'Content-Type': 'application/json'    }
  };
  
  // Solo agrega el cuerpo si no es una solicitud GET
  if (metodo !== 'GET') {
    config.body = JSON.stringify(body);
  }

  try {
    const response = await fetch(endpoint, config);

    if (!response.ok) {
      throw new Error(`Error al realizar la solicitud. Código de estado: ${response.status}`);
    }

    // Intenta obtener la respuesta como JSON
    return await response.json();
  } catch (error) {
    throw new Error(`Error al realizar la solicitud: ${error.message}`);
  }
}



