import {  renderPage,getCachedResourceJson} from '/src/resouces/js/commonRequest.js';

// Define la interfaz para el usuario
// Define la interfaz para el perfil de proyecto
interface Perfil {
  id: number;
  img: string;
  titulo: string;
  descripcion: string;
  github: string;
  enlinea: string;
  madewith: string;
  // Agregar otros campos según la estructura de los datos recibidos
}

// Función para manejar errores del servidor
const manejarErrorServidor = async () => {
  try {
    await renderPage('/pages/ServerError.hbs', { container: {} });
  } catch (error) {
    console.error('Error al renderizar la página de error del servidor:', error);
    // Mostrar mensaje al usuario o redirigir a una página de error genérica
    alert('Se produjo un error en el servidor. Por favor, inténtelo de nuevo más tarde.');
  }
};

// Función para obtener los datos de los proyectos usando fetch
const obtenerProyecto = async (): Promise<Perfil[]> => {
  try {
    // Realizar la solicitud a la API usando fetch
    const response = await getCachedResourceJson('https://lfgarcia.com/proyects-all');

    // Verificar si los datos obtenidos son un array
    if (Array.isArray(response)) {
      const proyectos: Perfil[] = response.map((proyecto: any) => ({
        id: Number(proyecto.id),
        img: proyecto.img ?? '',
        titulo: proyecto.titulo ?? '',
        descripcion: proyecto.descripcion ?? '',
        github: proyecto.github ?? '',
        enlinea: proyecto.enlinea ?? '',
        madewith: proyecto.madewith ?? ''
      }));

      // Ordenar los proyectos por ID de forma descendente
      const ordenproyects = proyectos.sort((a, b) => b.id - a.id);

      return ordenproyects;
    } else {
      console.error('Los datos obtenidos no son un array');
      return [];
    }
  } catch (error) {
    console.error('Error al obtener los datos de la API:', error);
    await manejarErrorServidor();
    return [];
  }
};

export default obtenerProyecto;
