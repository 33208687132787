import { renderPage,getCachedResourceJson } from '/src/resouces/js/commonRequest.js';
import { errorServer } from './500';

// Define la interfaz para el usuario
interface Perfil {
  id: number;
  titulo: string;
  descripcion: string;
  sobremi: string;
  linkedin: string;
  github: string;
  cv: string;
  // Agregar otros campos según la estructura de los datos recibidos
}

// Función para manejar errores del servidor
const manejarErrorServidor = async () => {
  try {
    await renderPage('/pages/ServerError.hbs', { container: {} });
  } catch (error) {
    console.error('Error al renderizar la página de error del servidor:', error);
  }
};

// Función para obtener los datos del perfil usando fetch
const obtenerPerfil = async (): Promise<Perfil[]> => {
  try {
    const response = await getCachedResourceJson('https://lfgarcia.com/cv/perfil-now'); // Asegúrate de usar HTTPS


    if (Array.isArray(response)) {
      return response.map((usuario: any) => {
        // Validar y sanitizar datos aquí si es necesario
        return {
          id: usuario.id ?? 0,
          titulo: usuario.titulo ?? '',
          descripcion: usuario.descripcion ?? '',
          sobremi: usuario.sobremi ?? '',
          linkedin: usuario.linkedin ?? '',
          github: usuario.github ?? '',
          cv: usuario.CV ?? ''
        };
      });
    } else {
      console.error('Los datos obtenidos no son un array');
      return [];
    }
  } catch (error) {
    console.error('Error al obtener los datos de la API:', error);
    await manejarErrorServidor();
    setTimeout(() => {
      // Considera redirigir o notificar al usuario
      errorServer();
    }, 500);
    return [];
  }
};

export default obtenerPerfil;
