import Handlebars from 'handlebars';
import  { routes } from '/src/resouces/js/routes.js';


// Esta función renderiza un template Handlebars en el elemento con el ID 'main'
export async function getCachedResource(url)
{
    const offlineId = `CACHED_${url}`;
    const offlineResource = localStorage.getItem(offlineId);

    if (offlineResource)
        return offlineResource;

    const res = await fetch(url);
    const content = await res.text();

    sessionStorage.setItem(offlineId, content);
    return content;
}

export async function getCachedResourceJson(url) {
  const offlineId = `CACHED_${url}`;
  const offlineResource = sessionStorage.getItem(offlineId);

  // Agregar un parámetro de timestamp para evitar caché del navegador
  const urlWithTimestamp = `${url}?timestamp=${Date.now()}`;

  try {
    const res = await fetch(urlWithTimestamp);
    if (!res.ok) {
      throw new Error(`HTTP error! status: ${res.status}`);
    }

    const jsonContent = await res.json(); // Obtener JSON directamente

    if (offlineResource) {
      const cachedContent = JSON.parse(offlineResource);

      // Comparar los datos actuales con los datos en caché
      if (JSON.stringify(jsonContent) !== JSON.stringify(cachedContent)) {
      //  console.log('Los datos han cambiado. Actualizando caché.');
        sessionStorage.setItem(offlineId, JSON.stringify(jsonContent));
      } 
    } else {
      // Si no hay caché, almacenar los datos actuales
      sessionStorage.setItem(offlineId, JSON.stringify(jsonContent));
    }

    return jsonContent; // Devolver el contenido en formato JSON

  } catch (error) {
    console.error('Error al obtener los datos de la API:', error);

    // Devolver los datos en caché si existe en caso de error
    if (offlineResource) {
      return JSON.parse(offlineResource);
    }

    throw error; // Lanzar error si no hay caché
  }
}




export async function renderPage(url, data = {}) {
  try {
    const content = await getCachedResource(url);
    const compiledTemplate = Handlebars.compile(content);
    const datoshtml = compiledTemplate(data);
    document.getElementById('main').innerHTML=datoshtml
   
      //////Visibility pass
    const passvisible = document.getElementById('pass')
    const materialIcon = document.getElementById('materialIcon') 
    materialIcon?.addEventListener('click',()=>{
  
      if(materialIcon.innerText==="visibility_on"){
        materialIcon.innerText="visibility_off"
      }
      else if(materialIcon.innerText==="visibility_off"){
        materialIcon.innerText="visibility_on"
      }
      if(passvisible.type==="password"){
        passvisible.type="text"
      }
      else{
        passvisible.type="password"
      }
    
      });
  
///////////////////////////////////////////////// Fin visibility

//////////////////////////////////////////////
  } catch (error) {
    console.error('Error rendering Handlebars template:', error);
  }

}

// Esta función realiza una solicitud GET a la API y devuelve los datos en formato JSON
export async function getApi(url) {
  const response = await fetch(url);
  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }
  return await response.json();
}

export async function request(metodo, url, body, clave) {
  const endpoint = `https://lfgarcia.com/${url}`;
  
  // Prepara los parámetros de la solicitud
  const config = {
    method: metodo,
    headers: {
      'Content-Type': 'application/json',
      'Authorization': clave, // Asegúrate de reemplazar 'your-token' con un token válido
    }
  };
  
  // Solo agrega el cuerpo si no es una solicitud GET
  if (metodo !== 'GET') {
    config.body = JSON.stringify(body);
  }

  try {
    const response = await fetch(endpoint, config);

    if (!response.ok) {
      throw new Error(`Error al realizar la solicitud. Código de estado: ${response.status}`);
    }

    // Intenta obtener la respuesta como JSON
    return await response.json();
  } catch (error) {
    throw new Error(`Error al realizar la solicitud: ${error.message}`);
  }
}

// Esta función navega a una nueva URL y carga la página correspondiente
export async function navigate(to) {
  history.pushState({}, '', to);
  await loadPage();
}


// Esta función carga la página basada en la ruta actual
export async function loadPage() {
  const currentRoute = location.pathname;
  const pageContent = routes[currentRoute];
  if (pageContent) {
    // Si existe una función asociada a la ruta, llámala
    await pageContent();
  } else {
    // Manejar la situación si la ruta no coincide con ninguna ruta registrada
    console.log('Página no encontrada');
  }
}




