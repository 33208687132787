import React, { useEffect } from 'react';
import '/src/vendor/css/bootstrap.min.css';
import '/src/vendor/js/popper.min.js';
import '/src/vendor/js/bootstrap.bundle.js';
import '/src/vendor/css/fontello.css';
import '/src/resouces/css/styleMenu.css';
import '/src/resouces/css/style.css';
import {requestMain,auth}  from '../src/resouces/js/requestMain.js';
import objetoTecnologia from './tecnologias'
import verificacion from './usuario'
import obtenerProyects from './proyects'
import * as jwt from 'jsonwebtoken';
import { Buffer } from 'buffer-es6';

global.Buffer = Buffer;

function generarJWT(usuario:any): string{
  // Aquí generamos el token JWT utilizando el método sign de la biblioteca jsonwebtoken
  let token = jwt.sign({ userId: usuario.id }, 'secreto', { expiresIn: '1m' });
  return token;
}
const now = new Date();
const keysecrect = generarJWT('refegar'+Math.random+now.getSeconds())

const App: React.FC = () => {
  useEffect(() => {
    const fetchData = async () => {
//////////////////////////////////////Primera Parte///////////////////////////////////

      try {
    
        const usuarios = await verificacion(sessionStorage.session);
        
        const usuarioEncontrado = usuarios.find(usuario => usuario.token === sessionStorage.session);


    // Verificar si la URL coincide con la de algún usuario
    // Si no se encuentra el usuario, redirigir al error 404
   
    if (usuarioEncontrado) {
      if(usuarioEncontrado.nombre===sessionStorage.nombre){
       
        const panelnav = document.getElementById('panelnav') as HTMLElement
        panelnav.innerHTML = `
          <div class="container-fluid-nav">
           <!--//////////////-->
           <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
           <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="#c6c6c6" class="bi bi-sliders" viewBox="0 0 16 16">
              <path fill-rule="evenodd" d="M11.5 2a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3M9.05 3a2.5 2.5 0 0 1 4.9 0H16v1h-2.05a2.5 2.5 0 0 1-4.9 0H0V3zM4.5 7a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3M2.05 8a2.5 2.5 0 0 1 4.9 0H16v1H6.95a2.5 2.5 0 0 1-4.9 0H0V8zm9.45 4a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3m-2.45 1a2.5 2.5 0 0 1 4.9 0H16v1h-2.05a2.5 2.5 0 0 1-4.9 0H0v-1z"/>
            </svg>
          </button> 
          <!--//////////////-->
          <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <!--//////////////-->
        <ul class="navbar-nav me-auto mb-lg-0">
          <li class="nav-item">
            <a class="nav-link nav-link-nav mt-2" href="#">C-Panel</a>
          </li>
          <li class="nav-item dropdown">
            
            <a class="nav-link dropdown-toggle nav-link-nav mt-2" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
              Editar
            </a>
            <!--//////////////-->
            <ul class="dropdown-menu">
              <li><a class="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#homeModal">Entrada</a></li>
              <li><a class="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#aboutModal">Sobre Mi</a></li>
              <li><hr class="dropdown-divider" ></li>
              <li><a class="dropdown-item" href="#proyects">Proyectos</a></li>
            </ul>
               <!--//////////////-->
          </li>
          <li class="nav-item crearPubl" href="#" data-bs-toggle="modal" data-bs-target="#crearPost">
            <a class="nav-link disabled p-1" aria-disabled="true" >
              <span>Crear publicacion</span>
              <svg width="20" height="19" viewBox="0 0 15 14" fill="#005dab" xmlns="http://www.w3.org/2000/svg" class="injected-svg" data-src="/gamification/header/create_story.svg" xmlns:xlink="http://www.w3.org/1999/xlink">
              <path d="M11.1484 6.63158L12.0768 5.70316C12.4011 5.37895 12.8137 5.20211 13.2632 5.15789V4.42105L8.84211 0H1.47368C0.655789 0 0 0.655789 0 1.47368V11.7895C0 12.6 0.655789 13.2632 1.47368 13.2632H5.89474V11.8853L5.99053 11.7895H1.47368V1.47368H6.63158V6.63158H11.1484ZM8.10526 1.10526L12.1579 5.15789H8.10526V1.10526ZM11.8853 7.98L13.3884 9.48316L8.87158 14H7.36842V12.4968L11.8853 7.98ZM14.6263 8.24526L13.9042 8.96737L12.4011 7.46421L13.1232 6.74211C13.2632 6.59474 13.5063 6.59474 13.6537 6.74211L14.6263 7.71474C14.7737 7.86211 14.7737 8.10526 14.6263 8.24526Z"></path>
              </svg>
              </a>
            </li>
          </ul>
               <!--//////////////-->
      </div>
      </div>
    </div>
    <div class="nav-link-nav navbar-user fs-5"><span>!Hola: ${usuarioEncontrado.nombre}</span></div>
 
        `;  
      } 
    }
    
      } catch (error) {
        console.log('Bienvenido a nuestra pagina');
        
      }

      /////////////////////////////////////////////////////////////////////////
      ///// Inicio Create Post
    
      const modal = document.createElement('div');
      modal.className = 'modal fade mt-5 pb-5 ';
      modal.id = `crearPost`; // Usar el data-id para el id del modal
      modal.setAttribute('data-bs-backdrop', 'static')
      modal.setAttribute('data-bs-keyboard', 'false');
      modal.innerHTML = `
          <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h1 class="font-weight-bold">Mis proyectos</h1>
        <button class="btn-close" data-bs-dismiss="modal" id="closeButtonModal" onclick="window.location.reload()"></button>
      </div>
      <div class="modal-body" style="display: flex; flex-wrap: wrap; justify-content: center;">
        <div class="col">
          <div class="miProyect">
            <div class="proyectsEdit">
  
            <form id="uploadfileimg" action="/upload-img-proyect" method="post" enctype="multipart/form-data" style="display:none;">
              <input type="file" name="archivo" id="fileimg">
            </form>
  
           
              <div class="editImage" id="editImage">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="25" height="30">
                  <path d="M0 96C0 60.7 28.7 32 64 32H448c35.3 0 64 28.7 64 64V416c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V96zM323.8 202.5c-4.5-6.6-11.9-10.5-19.8-10.5s-15.4 3.9-19.8 10.5l-87 127.6L170.7 297c-4.6-5.7-11.5-9-18.7-9s-14.2 3.3-18.7 9l-64 80c-5.8 7.2-6.9 17.1-2.9 25.4s12.4 13.6 21.6 13.6h96 32H424c8.9 0 17.1-4.9 21.2-12.8s3.6-17.4-1.4-24.7l-120-176zM112 192a48 48 0 1 0 0-96 48 48 0 1 0 0 96z" fill="white"/>
                </svg>  
              </div>
              <div id="progressUpdateImg" class="ms-3"></div>
              <span class="ms-2 mb-2" style="font-style:italic; color:#754ef9;" id="spanEditImage"></span>
              <div class="img mb-3" ><img src='' alt="" id="imgeditproyect" style="object-fit: cover;"></div>
              <div class="modelProyectsEdit">
                <div class="title mb-1 font-weight-bolder">
                <h3>Titulo:</h3>
                <textarea id="titleditproyect"></textarea>
                <span id="wordCountitle" style="font-size:15px;color:#754ef9;"></span>
                </div>
                <div class="descripcionEdit pb-2">
                <h3>Descripcion:</h3>
                <textarea id="textDescrArea" rows="9"></textarea>
                <span id="wordCountDescr" style="font-size:15px;color:#754ef9;;font-weight:bold;"></span>
                </div>
                <div class="portaSocialEdit">
                  <div>
                    <div class="githubEdit mt-2 me-3 w-100 "><i class="bx bxl-github"><span>Github:</span></i>
                      <textarea name="updateGithub" id="updateGithub" rows="1"></textarea>
                      </div>
                      <span id="wordCountGit" style="font-size:15px;color:#754ef9;font-weight:bold;margin-top:15px;"></span>
  
                  </div>
                  <div>
                    <div class="View-Online-Edit mt-4 w-100"><i class="bx bx-world"><span>Online</span></i>
                      <textarea name="" id="updateEnlinea" rows="1"></textarea>
                    </div>
                    <span id="wordCountOnline" style="font-size:15px;color:#754ef9;font-weight:bold;margin-top:10px;margin-bottom:10px;"></span>
  
                  </div>
                </div>
              </div>
              <div class="ps-2 fs-5 m-3"><p style="margin:0;margin-top: 5px;">Tecnologías desarrolladas con:</p></div>
              <div class="madeWithIconEdit ms-4 mb-4" id="madeWithIconEdit"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer"><button class="btn btn-primary" id="updateproyectid">Subir proyecto</button></div>
    </div>
  </div>
  `;
  
  
  document.body.append(modal); /// debe ir en este orden
  
  //////////////////////////////////////////////////////
  /////////////////////////////////////////////Parte de limit word
  //// titulo
  const wordLimittitle = 70;
  const titleditproyect = document.getElementById(`titleditproyect`) as HTMLInputElement
  const wordCountitle = document.getElementById(`wordCountitle`) as HTMLInputElement
  
  titleditproyect.addEventListener('input', () => {
  let text = titleditproyect.value;
  let charCount = text.length;
  
  if (charCount > wordLimittitle) {
  titleditproyect.value = text.slice(0, wordLimittitle);
  charCount = wordLimittitle;
  
  }
  
  wordCountitle.textContent =  `${charCount}/${wordLimittitle} Caracteres`;
  
  })
  //// Fin titulo
  
  //// descripcion
  const wordLimitDescr = 300;
  const textDescrArea = document.getElementById(`textDescrArea`) as HTMLInputElement
  const wordCountDescr = document.getElementById(`wordCountDescr`) as HTMLInputElement
  
  textDescrArea.addEventListener('input', () => {
  let text = textDescrArea.value;
  let charCount = text.length;
  
  if (charCount > wordLimitDescr) {
  textDescrArea.value = text.slice(0, wordLimitDescr);
  charCount = wordLimitDescr;
  
  }
  
  wordCountDescr.textContent =  `${charCount}/${wordLimitDescr} Caracteres`;
  
  })
  //// Fin descripcion
  
  //// Github
  const wordLimitGit = 100;
  const updateGithub = document.getElementById(`updateGithub`) as HTMLInputElement
  const wordCountGit = document.getElementById(`wordCountGit`) as HTMLInputElement
  
  updateGithub.addEventListener('input', () => {
  let text = updateGithub.value;
  let charCount = text.length;
  
  if (charCount > wordLimitGit) {
  updateGithub.value = text.slice(0, wordLimitGit);
  charCount = wordLimitGit;
  
  }
  
  wordCountGit.textContent =  `${charCount}/${wordLimitGit} Caracteres`;
  
  })
  //// Fin Github
  
  //// Online
  const wordLimitOnline = 100;
  const updateEnlinea = document.getElementById(`updateEnlinea`) as HTMLInputElement
  const wordCountOnline = document.getElementById(`wordCountOnline`) as HTMLInputElement
  
  updateEnlinea.addEventListener('input', () => {
  let text = updateEnlinea.value;
  let charCount = text.length;
  
  if (charCount > wordLimitOnline) {
  updateEnlinea.value = text.slice(0, wordLimitOnline);
  charCount = wordLimitOnline;
  
  }
  
  wordCountOnline.textContent =  `${charCount}/${wordLimitOnline} Caracteres`;
  
  })
  //// Fin Online
  //////////////////////////////////////////////Fin limit word
  
  //////hay que reciclar codigo de otro

 //////////////////////////////////////////////Fin primera parte///////////////////////////

  const tecnologias = await objetoTecnologia()
  const proyectPost = await obtenerProyects()
  const numNuevaEntrada = proyectPost.length+1
  const editWithClick = document.querySelectorAll(`#madeWithValue li`)
  const madeWithIconEdit = document.querySelector(`.madeWithIconEdit`) 
  if (madeWithIconEdit) {
  madeWithIconEdit.innerHTML = '';
  const addedElements = new Set();
  tecnologias.forEach((element) => {
  
  const textContent = element.nombre?.trim();
  if (textContent && !addedElements.has(textContent)) {
  const input = document.createElement('input');
  input.style.marginRight = '5px';
  input.type = 'checkbox';
  input.id = textContent;
  input.name = textContent;
  input.value = textContent;
  
  const label = document.createElement('label');
  label.textContent = textContent;
  label.setAttribute('for', input.id);
  let encontrado = false;
  editWithClick.forEach((liElement:any) => {
  if (liElement.textContent?.trim() === textContent && !addedElements.has(textContent)) {
  encontrado = true;
  addedElements.add(textContent);
  
  }
  });
  if (encontrado) {
  input.checked = true;
  }
  madeWithIconEdit.appendChild(input);
  madeWithIconEdit.appendChild(label);
  madeWithIconEdit.appendChild(document.createElement('br'));
  addedElements.add(textContent);
  }
  });
  }
  /////////////////////////////////////////////////////////////
  
  ////////////////////////////////////////////////////////////////////////////////////
  //////////// Carga imagen de proyecto
  
  const cargarimgElements = document.getElementById(`editImage`) as HTMLElement
  const cargafileimg = document.getElementById('fileimg') as HTMLInputElement;
  const spanEditImage = document.getElementById(`spanEditImage`) as HTMLElement
  spanEditImage.textContent = `Click en el icono para subir imagen de portada por favor...`

  // Limitar la selección de archivos solo a imágenes
  cargafileimg.setAttribute('accept', 'image/*');
  
  if (!cargafileimg.dataset.clickEventAttached) {
    cargafileimg.dataset.clickEventAttached = 'true';
  
   const handleClick = () => {
    cargafileimg.click();
   };
  
   cargarimgElements.addEventListener('click', handleClick);
   
  }
  
  if (!cargafileimg.dataset.changeEventAttached) {
   cargafileimg.dataset.changeEventAttached = 'true';
  
   const handleChange = () => {
     const spanEditImage = document.getElementById(`spanEditImage`) as any;
     const imgeditproyect = document.getElementById(`imgeditproyect`) as any;
     const progressUpdateImg = document.getElementById(`progressUpdateImg`) as any;
     
     ;

     // Crear elemento <progress> para mostrar el progreso
     let progress = document.createElement('progress');
     progressUpdateImg.appendChild(progress);
  
     if (cargafileimg.files && cargafileimg.files.length > 0) {
       const file = cargafileimg.files[0];
       
       // Validar el tipo de archivo
       const validImageTypes = ['image/jpeg', 'image/png', 'image/gif', 'image/webp'];
       if (!validImageTypes.includes(file.type)) {
         spanEditImage.textContent = 'El archivo seleccionado no es una imagen válida';
         return;
       }
       
       // Validar el tamaño del archivo
       const maxSizeInMB = 3;
       if (file.size > maxSizeInMB * 1024 * 1024) {
         spanEditImage.textContent = `El archivo seleccionado excede el límite de ${maxSizeInMB} MB`;
         return;
       }
  
       spanEditImage.textContent = `Subiendo... imagen... Archivo seleccionado: ${file.name}`;
       console.log('Nueva imagen generada', file);
  
       // Crear una URL para la imagen seleccionada
       const reader = new FileReader();
  
       reader.onload = (e:any) => {
         imgeditproyect.src = e.target.result as string;
       };
  
       reader.readAsDataURL(file);
  
       // Mostrar el progreso de carga de la imagen
       let xhr = new XMLHttpRequest();
       xhr.responseType = "blob";
       xhr.open("get", URL.createObjectURL(file));
       xhr.addEventListener('load', () => {
         if (xhr.status == 200) {
           let imagen_blob = xhr.response;
           let url = URL.createObjectURL(imagen_blob);
           imgeditproyect.src = url;
           progress.style.display = 'none';
         }
       });
  
       xhr.addEventListener('progress', (e) => {
         if (e.lengthComputable) {
           let porcentaje = (e.loaded * 100) / e.total;
           progress.value = porcentaje;
         }
       });
  
       xhr.send();
     } else {
  
       spanEditImage.textContent = 'No se seleccionó ningún archivo';
     }
   };
  
   cargafileimg.addEventListener('change', handleChange);
  }
  
  
  // Configuración para cargar el archivo
  


  const updateproyectid = document.getElementById(`updateproyectid`) as HTMLElement;
  
  if (!updateproyectid.dataset.clickEventAttached) {
  updateproyectid.dataset.clickEventAttached = 'true';
  
  updateproyectid?.addEventListener('click', () => {
  const fileInput = document.getElementById(`fileimg`) as HTMLInputElement;
  
  if (fileInput && fileInput.files && fileInput.files.length > 0) {
  
  const formData = new FormData();
  const files = fileInput.files[0]
  // Toco hacerlo así para que el compilador webpack no se reinicie
   formData.append('file', files );
   formData.append('filename',`proyecto-`+numNuevaEntrada ); // Añade el nombre del archivo deseado como parte del formulario
  
  
  //////////////////////////////////////////////////////////////////////////
  ////////////////////////////
  
  fetch('https://lfgarcia.com/upload-img-proyects', {
    method: 'POST',
    body: formData,
    headers: {
      'Authorization': keysecrect // Asegúrate de reemplazar
    }
  })
  .then(response => response.text())
  .then(() => {
    const titulo = document.getElementById(`titleditproyect`) as HTMLInputElement
    const descripcion = document.getElementById(`textDescrArea`) as HTMLInputElement
    const github = document.getElementById(`updateGithub`) as HTMLInputElement
    const enlinea = document.getElementById(`updateEnlinea`) as HTMLInputElement
  
    const checkboxes = document.querySelectorAll(`#madeWithIconEdit input[type="checkbox"]`) as any
  // Crear un array con los valores de los checkboxes que están marcados
   const checkedValues = Array.from(checkboxes)
  .filter((checkbox:any) => checkbox.checked)
     .map((checkbox:any) => checkbox.value);
     // Convertir el array en una cadena de texto con el formato deseado
    const formattedString = checkedValues.join(', ') + ' ';
    // Puedes usar la cadena de texto formattedString según tus necesidades
    
  ///////////////////////////////////////////////////////////////////
  ///////////////////////// Peticion validacion
  
       if(titulo.value.length===0){
       alert('Valor Titulo esta vacio')
       }
       else if(descripcion.value.length===0){
        alert('Valor Descripcion esta vacio')
       }
       else if(github.value.length===0){
        alert('Valor Github esta vacio')
       }
       else if(enlinea.value.length===0){
        alert('Valor Online esta vacio')
      
       }
       else if(formattedString.length<=1){
        alert('Ningun campo tecnologico esta seleccionado')
       }
  
  
       else{
  
       alert('Informacion se creo correctamente');
       requestMain('put',`newProyecto/`,{
       img:`proyecto-${numNuevaEntrada}.png`,
       titulo:titulo.value,
       descripcion:descripcion.value,
       github:github.value,
       enlinea:enlinea.value,
       madewith:formattedString
    }).then(()=>{
      window.location.reload()
    })
  }
    ///////////////////////////////////////////////////////////////////
    ///////////////////////
    
  })
  .catch(error => {
    console.error('Error al cargar el archivo:', error);
  });
  
  ///////////////////////////////////////////////////////////////////////////////////
  }
  
  else {
        alert('No se seleccionó ningún archivo para cargar.');
      }
  
  });
  }
  
  
  //////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////
    
  
  
    }

    fetchData();
  }, [])

  return (
    <>
    <header >
     <nav id="panelnav" className="navbar navbar-expand-sm w-100 m-0 pt-0 pb-0 pe-5 ps-5" 
     style={{ 
      display: 'flex', 
      flexWrap: 'wrap',
      justifyContent: 'space-between',
 
      }}></nav>
      <nav id="portfolio" className="navbar navbar-expand-sm  d-flex justify-content-between"></nav>
    </header>
    <main ></main>
    <footer></footer>
    </>
  );
};

export default App;
