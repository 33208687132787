const errorServer = () => {
  const errormain = document.querySelector('main');
  errormain.innerHTML = `
    <div style="position: relative; font-size: 20px; display: flex; flex-wrap: wrap; justify-content: center;">
      <img src="/resouces/img/error/505.png" alt="505" height="600px" style="border-radius: 20px;">
      <div style="padding: 40px 20px 30px 100px; width:500px;">
        <span style="font-weight: bold; font-size:25px;">Internal server error - 500</span>
        <p class="mt-2">Error en el servidor por favor esperar mientras el equipo repara el servidor</p>
      </div>
    </div>`;
};

export { errorServer };