import '/src/vendor/css/bootstrap.min.css';
import '/src/resouces/css/style.css';
import { renderPage, navigate ,request } from '/src/resouces/js/commonRequest.js';

const ValidateContacto = async () => {
  const nombrevalidacion = document.getElementById('nombrevalidacion') as HTMLElement;
  const emailvalidacion = document.getElementById('emailvalidacion') as HTMLElement;
  const Preguntasval = document.getElementById('PreguntasValid') as HTMLElement;
  const comentariosvalid = document.getElementById('comentariosvalid') as HTMLElement;
  const contactoValidacion = document.getElementById('contactoValidacion') as HTMLElement;
  const nombreval = document.getElementById('nombre') as HTMLInputElement;
  const emailval = document.getElementById('email') as HTMLInputElement;
  const menuPreguntas = document.getElementById('menuPreguntas') as HTMLSelectElement;
  const comentarios = document.getElementById('comentarios') as HTMLTextAreaElement;

  interface ValidationState {
      [key: string]: boolean;
  }

  interface Validation {
      state: ValidationState;
      subscribers: ((state: ValidationState) => void)[];
      updateState: (id: string, stateValid: boolean) => void;
      subscribe: (callbackFunction: (state: ValidationState) => void) => void;
      isValid: () => boolean;
  }

  const validation: Validation = {
      state: {},
      subscribers: [],
      updateState: function (id: string, stateValid: boolean) {
          this.state[id] = stateValid;
          this.subscribers.forEach((subscriber) => {
              subscriber(this.state);
          });
      },
      subscribe: function (callbackFunction: (state: ValidationState) => void) {
          this.subscribers.push(callbackFunction);
      },
      isValid: function () {
          for (const id in this.state) {
              if (!this.state[id]) {
                  return false;
              }
          }
          return true;
      }
  };

  validation.subscribe((state: ValidationState) => {
      if (state.nombre) {
          nombrevalidacion.style.display = "block";
          nombrevalidacion.style.color = "#fff";
          nombrevalidacion.style.backgroundColor = "darkgreen";
      } else {
          nombrevalidacion.style.color = "#fff";
          nombrevalidacion.style.backgroundColor = "rgb(194, 0, 0)";
      }

      if (state.email) {
          emailvalidacion.style.display = "block";
          emailvalidacion.style.color = "#fff";
          emailvalidacion.style.backgroundColor = "darkgreen";
      } else {
          emailvalidacion.style.color = "#fff";
          emailvalidacion.style.backgroundColor = "rgb(194, 0, 0)";
      }

      if (state.menuPre) {
          Preguntasval.style.display = "block";
          Preguntasval.style.color = "#fff";
          Preguntasval.style.backgroundColor = "darkgreen";
      } else {
          Preguntasval.style.color = "#fff";
          Preguntasval.style.backgroundColor = "rgb(194, 0, 0)";
      }

      if (state.comment) {
          comentariosvalid.style.display = "block";
          comentariosvalid.style.color = "#fff";
          comentariosvalid.style.backgroundColor = "darkgreen";
      } else {
          comentariosvalid.style.color = "#fff";
          comentariosvalid.style.backgroundColor = "rgb(194, 0, 0)";
      }
  });

  contactoValidacion?.addEventListener('submit', (e) => {
      e.preventDefault();
      
      const validNombre = nombreval.value.trim().length >= 4;
      const validEmail = /\S+@\S+\.\S+/.test(emailval.value.trim());
      const Preguntasvalidad = menuPreguntas.value.trim().length > 0;
      const comentValid = comentarios.value.trim().length >= 4;
      
      if (validNombre) {
          validation.updateState("nombre", true);
          nombrevalidacion.innerText = "Usuario Validado";
          nombreval.classList.add('is-valid');
      } else {
          nombrevalidacion.innerText = nombreval.value.length === 0 ? "Campo usuario está vacío!" : "Campo mínimo 4 de longitud!";
          validation.updateState("nombre", false);
          nombrevalidacion.style.display = "block";
          nombreval.classList.remove('is-valid');
      }

      if (validEmail) {
          validation.updateState("email", true);
          emailvalidacion.innerText = "E-mail Validado";
          emailval.classList.add('is-valid');
      } else {
          emailvalidacion.innerText = "E-mail no válido!";
          validation.updateState("email", false);
          emailvalidacion.style.display = "block";
          emailval.classList.remove('is-valid');
      }

      if (Preguntasvalidad) {
          validation.updateState("menuPre", true);
          Preguntasval.innerText = "Pregunta Validada";
          Preguntasval.style.display = "block";
      } else {
          Preguntasval.innerText = menuPreguntas.value.length === 0 ? "Seleccionar una pregunta por favor!" : "Campo pregunta está vacío!";
          validation.updateState("menuPre", false);
          Preguntasval.style.display = "block";
      }

      if (comentValid) {
          validation.updateState("comment", true);
          comentariosvalid.innerText = "Comentario Validado";
          comentariosvalid.style.display = "block";
      } else {
          comentariosvalid.innerText = comentarios.value.length === 0 ? "Campo comentario está vacío!" : "Comentarios mínimo 4 caracteres";
          validation.updateState("comment", false);
          comentariosvalid.style.display = "block";
      }

      if (validation.isValid()) {
          // Lógica para manejar el formulario cuando todos los campos son válidos
          //alert('Formulario válido y fue enviado, Muchas gracia!');

          ///////////////////////////////////////////////////////////////////////////
         
          if (contactoValidacion instanceof HTMLFormElement) {
            nombreval.classList.remove('is-valid');
            emailval.classList.remove('is-valid');
            nombrevalidacion.style.display="none"
            emailvalidacion.style.display="none"
            Preguntasval.style.display="none"
            comentariosvalid.style.display="none"
        }
          ///////////////////////////////////// Loading send
          const sendLoading = document.createElement('div') 
          sendLoading.id="sendLoading"
          const loadingGif = document.createElement('img')
          loadingGif.style.position="fixed"
          loadingGif.style.top="45%"
          loadingGif.style.left="45%"
          loadingGif.style.animation='rotate 1s linear infinite';
          loadingGif.src='/resouces/img/load.svg';
          document.getElementById('contact')?.appendChild(sendLoading)
          document.getElementById('sendLoading')?.appendChild(loadingGif)
          const mensajeEnvion = document.querySelector('input[type="submit"]') as HTMLInputElement;
          mensajeEnvion.value="Esperar mandando al E-mail..."


          ///////////////////////////////////////////////////////////////////////////////
           request('post',`api/auth/contacto`,{
            "Correo" :`${emailval.value}`,
            "Nombre":`${nombreval.value}`,
            "comentarios":`${comentarios.value}`,
            "asunto":`${menuPreguntas.value}`
         },'').then(() => {
           loadingGif.style.display = 'none';
           const mensajeEnvion = document.querySelector('input[type="submit"]') as HTMLInputElement;
           mensajeEnvion.value="Todo Ok"
           setTimeout(()=>{
             alert('Envio fue exitoso, Muchas gracias!')

             if (contactoValidacion instanceof HTMLFormElement) {
                contactoValidacion.reset();
            }
             
            },500)
           // Ocultar el GIF de carga cuando se completa la solicitud
          
           // Aquí puedes manejar la respuesta de la solicitud
       }).catch(error => {
           // Ocultar el GIF de carga en caso de error
           loadingGif.style.display = 'none';
           // Aquí puedes manejar el error
       });  

      }
  });
}

export default ValidateContacto;