import { createRoot } from 'react-dom/client';
import App from './App';
import {loadPage,navigate,renderHomeHeader,renderHomeMain,renderHomeProyects,renderFooter,requestMain}  from '../src/resouces/js/requestMain.js';
import objetoPerfil from './descripcion';
import obtenerProyects from './proyects'
import contactoValidate from './contactoValidate'
import objetoTecnologia from './tecnologias'
import validarUsuario from './usuario'
import anime from 'animejs';



///proyects
loadPage()
/////////////////////////////////////////////////////////////////////////////////
//Header
//////////////////////////////////////Primer sector vericado

// Renderiza la cabecera de la página home y luego ejecuta el código para manejar los enlaces
renderHomeHeader('/pages/home/header.hbs', { container: { undefined } }).then(() => {
  // Obtiene todos los enlaces dentro del contenedor #port
  const links = document.querySelectorAll('header a');

  // Recorre cada enlace para agregar un evento de clic
  links.forEach((link: any) => {
    link.addEventListener('click', (e: Event) => {
      e.preventDefault(); // Evita que el enlace realice la navegación predeterminada
      handleLinkClick(link); // Llama a la función para manejar el clic en el enlace
    });

    // Si la ruta actual es vacía o coincide con la del enlace, agrega la clase 'activar'
    if (location.pathname === '' || link.pathname === location.pathname) {
      toggleActiveClass(link); // Agrega la clase 'activar' al enlace correspondiente
    }
  });

  // Obtiene todos los enlaces en la página
  const allLinks = document.querySelectorAll('a');
  // Recorre cada enlace para manejar la activación/desactivación de clases
  allLinks.forEach(link => {
    link.addEventListener('click', () => {
      removeActiveClasses(allLinks); // Remueve la clase 'activar' de todos los enlaces
      toggleActiveClass(link); // Agrega la clase 'activar' al enlace que se clicó
    });
  });
});

// Función para manejar el clic en un enlace
function handleLinkClick(link: HTMLAnchorElement) {
  const pathname = link.pathname.replace('/', ''); // Elimina la barra inicial de la ruta
  const targetElement = document.getElementById(pathname); // Busca el elemento con el ID correspondiente a la ruta

  // Si se encuentra el elemento, desplaza la vista suavemente hasta él
  if (targetElement) {
    targetElement.scrollIntoView({ behavior: 'smooth' });
  }
}

// Función para agregar la clase 'activar' a un enlace
function toggleActiveClass(link: HTMLAnchorElement) {
  link.classList.add('activar');
}

// Función para remover la clase 'activar' de todos los enlaces
function removeActiveClasses(links: NodeListOf<HTMLAnchorElement>) {
  links.forEach(link => link.classList.remove('activar'));
}


////Fin Primer sector
/////////////////////////////////////////////////////////////////////////////
// dato de perfil sobre mi de main 

///////////////////////////Segundo sector verificacion

 export async function obtenerPerfil() {
    try {
      
        // Obtiene todos los datos del perfil, proyectos, usuarios y tecnologías
    const usuarios = await validarUsuario(sessionStorage.session) as any
    const todo = await objetoPerfil();
    const perfilDescri = todo.find(perfil => perfil.id === 1); // Encuentra el perfil con ID 1
    const proyects = await obtenerProyects(); // Obtiene los proyectos
    const tecnologias = await objetoTecnologia(); // Obtiene las tecnologías
    let clicknumberpages = proyects.length; // Calcula el número de páginas según la cantidad de proyectos


      renderHomeMain('/pages/home/main.hbs', {container: {perfilDescri}}).then(()=>{

        const updateAbout = document.getElementById('updateAbout') as HTMLElement
        const updateEntrance = document.getElementById('updateEntrance') as HTMLElement
        const sobremiEdit = document.getElementById('aboutEdit') as HTMLInputElement 
        const titleEdit = document.getElementById('titleEdit') as HTMLInputElement
        const descripEdit = document.getElementById('descripEdit') as HTMLInputElement
        const linkedin = document.getElementById('linkeEdit') as HTMLInputElement
        const github = document.getElementById('gitEdit') as HTMLInputElement
        const CV = document.getElementById('cvEdit') as HTMLInputElement

        ////////////////////////////////////////////////////////////////////////
        contactoValidate();
         ////////////////////////////////////////////////////////////////////Icon-footer
               // Actualiza los enlaces de los iconos del footer con los valores obtenidos de los inputs
         const footergithub = document.querySelector('#footergithub') as any;
         const footerlinkedin = document.querySelector('#footerlinkedin') as any;
         footergithub.href=github.value
         footerlinkedin.href=linkedin.value

        /////////////////////////////// Actualizar sobre mi

        updateAbout.addEventListener('click',(e)=>{
          e.preventDefault()
          requestMain('put','actualizar/presentacion',{
            sobremi: sobremiEdit.value}).then(()=>{
              alert('Actualizacion Exitosa!')
              window.location.reload();
            },).catch((error)=>{
            alert('Error en actualizar')
          })

          ////////////////////////////////////// Actualizar entrance
    
        })

        animationScroll('#aboutImg')
        animationScroll('#presentImg')
        animationScroll('#proyects')

        //////////////////////////////////////////////////

      function animationScroll(animar:string){

        const animateElement = () => {
          anime({
            targets: animar,
            translateY: [0], // Movimiento desde -50px a 0px
            opacity: [0, 1], // Cambia la opacidad de 0 a 1
            easing: 'easeOutExpo', // Efecto de suavizado
            duration: 4000, // Duración de la animación en milisegundos
            delay: anime.stagger(100), // Retraso entre cada elemento animado
          });
        };
        
        // Ejecuta la animación
        
          animateElement();
      
          const targets = document.querySelector(animar) as HTMLElement
          const observer = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
              if (entry.isIntersecting) {
                animateElement(); // Inicia la animación cuando el elemento es visible
                // Deja de observar el elemento después de la animación
              }
            });
          }, { threshold: 0.1 }); // El 10% del elemento debe estar visible para activar la animación
          
          // Observar cada elemento
          
            observer.observe(targets);
      }
        
      // Evento para actualizar la sección de "Entrada" (Título, descripción y enlaces)
////////////////////////////////////////////
        updateEntrance.addEventListener('click',(e)=>{
          e.preventDefault()
          requestMain('put','actualizar/titulo',{
            titulo: titleEdit.value,
            descripcion: descripEdit.value,
            linkedin:linkedin.value,
            github:github.value,
            cv:CV.value

          }).then(()=>{
              alert('Actualizacion Exitosa!')
              window.location.reload();
            }).catch((error)=>{
            alert('Error en actualizar')
          })
        })
        


        ////////////////////////////////////Fin Segundo sector
        
           /////////////////////////////////////////////////////////////////
           ///Mostrar pagina de proyecto
//////////////////////////////////////Inicio Tercera parte de proyecto//////////////////////

const pagenumbernow = document.getElementById('pagenumbernow') as HTMLElement;
let pageCount = 1;

// Crear números de página
for (let i = 1; i <= proyects.length; i++) {
  if (i % 3 === 0) {
    const numberanow = document.createElement('a');
    numberanow.href = '#proyects';
    numberanow.style.cursor = 'default';
    const numberspannow = document.createElement('span');
    numberspannow.style.cursor = 'pointer';
    numberspannow.textContent = `${pageCount}`;
    numberanow.appendChild(numberspannow);
    pagenumbernow.appendChild(numberanow);
    pageCount++;
  }
}

// Asegurar que siempre haya al menos un número de página
if (proyects.length < 3) {
  const numberanow = document.createElement('a');
  numberanow.href = '#proyects';
  numberanow.style.cursor = 'default';
  const numberspannow = document.createElement('span');
  numberspannow.style.cursor = 'pointer';
  numberspannow.textContent = '1';
  numberanow.appendChild(numberspannow);
  pagenumbernow.appendChild(numberanow);
} else if (proyects.length % 3 !== 0) {
  const numberanow = document.createElement('a');
  numberanow.href = '#proyects';
  numberanow.style.cursor = 'default';
  const numberspannow = document.createElement('span');
  numberspannow.style.cursor = 'pointer';
  numberspannow.textContent = `${pageCount}`;
  numberanow.appendChild(numberspannow);
  pagenumbernow.appendChild(numberanow);
}

// Manejar los eventos de clic en los números de página
const clickpagenumbernow = document.querySelectorAll('#pagenumbernow span') as NodeListOf<HTMLElement>;

clickpagenumbernow.forEach((clickpage: HTMLElement) => {

  if (clickpage.textContent === `${clicknumberpages / proyects.length}`) {
    // Aplicar estilo al span clicado
    clickpage.classList.add('active-page');
    } 


  clickpage.addEventListener('click', (e: Event) => {
    e.preventDefault();

    // Resetear el estilo de todos los spans
    clickpagenumbernow.forEach((el: HTMLElement) => {
      el.classList.remove('active-page');
    });

    // Aplicar estilo al span clicado
    clickpage.classList.add('active-page');

    const texto: string | null = clickpage.textContent;
    const numfinal = parseInt(texto ?? '0', 10);

    // Limpiar la sección anterior
    const cleanproyects = document.getElementById('proyectsAll');
    if (cleanproyects) {
      cleanproyects.innerHTML = '';
    }
    proyectsLoads(numfinal);
  });
});

// Manejar el botón de página anterior
const previusNumberpages = document.getElementById('previusNumberpages') as HTMLElement;
const nextNumberpages = document.getElementById('nextNumberpages') as HTMLElement;

if (previusNumberpages) {
  previusNumberpages.addEventListener('click', (e: Event) => {
    e.preventDefault();
    let highlightedNumber = Array.from(clickpagenumbernow).findIndex(span =>
      window.getComputedStyle(span).backgroundColor === 'rgb(117, 78, 249)'
    );
    highlightedNumber = highlightedNumber > 0 ? highlightedNumber : 0;

    if (highlightedNumber > 0) {
      highlightedNumber--;
      const numFinal = parseInt(clickpagenumbernow[highlightedNumber].textContent ?? '0', 10);

      // Limpiar la sección anterior
      const cleanproyects = document.getElementById('proyectsAll');
      if (cleanproyects) {
        cleanproyects.innerHTML = '';
      }
      proyectsLoads(numFinal);

      // Resetear el estilo de todos los spans
      clickpagenumbernow.forEach((el: HTMLElement) => {
        el.classList.remove('active-page');
      });

      // Aplicar estilo al span correspondiente
      if (highlightedNumber >= 0 && highlightedNumber < clickpagenumbernow.length) {
        clickpagenumbernow[highlightedNumber].classList.add('active-page');
      }
    }
  });
}

if (nextNumberpages) {
  nextNumberpages.addEventListener('click', (e: Event) => {
    e.preventDefault();
    let highlightedNumber = Array.from(clickpagenumbernow).findIndex(span =>
      window.getComputedStyle(span).backgroundColor === 'rgb(117, 78, 249)'
    );
    highlightedNumber = highlightedNumber >= 0 ? highlightedNumber : -1;

    if (highlightedNumber < clickpagenumbernow.length - 1) {
      highlightedNumber++;
      const numFinal = parseInt(clickpagenumbernow[highlightedNumber].textContent ?? '0', 10);

      // Limpiar la sección anterior
      const cleanproyects = document.getElementById('proyectsAll');
      if (cleanproyects) {
        cleanproyects.innerHTML = '';
      }
      proyectsLoads(numFinal);

      // Resetear el estilo de todos los spans
      clickpagenumbernow.forEach((el: HTMLElement) => {
        el.classList.remove('active-page');
      });

      // Aplicar estilo al span correspondiente
      if (highlightedNumber < clickpagenumbernow.length) {
        clickpagenumbernow[highlightedNumber].classList.add('active-page');
      }
    }
  });
}

// Añadir estilos en el archivo CSS
const style = document.createElement('style');
style.textContent = `
  .active-page {
    background-color: #754ef9;
    color: #fff;
    padding: 5px 10px;
    border-radius: 20px;
  }
`;
document.head.appendChild(style);


/////////////////////////////////////////////Fin tercera parte /////////////////////////
//////////////////////////////////////////////////////////////////////


      proyectsLoads(1)

////////////////////////////////////////////////////////////////Cuarta parte ///////////
      function proyectsLoads(number:number) {

        ////////////////////////////////////////
        //////Aca grabar los ultimos proyectos para mostrar
        
        const final =(number*3)
        const inicial = (final-3)
        const firstThreeIDs = proyects.map(item => item.id).slice(inicial, final);
        const filteredProjects = proyects.filter(item => firstThreeIDs.includes(item.id));
   

        filteredProjects.forEach(proyectos => {
////////////////////////////////////////////////////////////// 
//////Aregamos secciones proyectos sin  plantillas 
          const proyectsosall = document.getElementById('proyectsAll')
          const mainModal = document.querySelector('main')
          const article = document.createElement('article');
          article.className = 'article col';
          article.innerHTML = `

           <div class="article">
           <div class="miProyect" >
           <div class="proyects" >
          <div id="setProyect">
          <div id="editProyect" data-id="${proyectos.id}">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="25" height="30">
                <path d="M471.6 21.7c-21.9-21.9-57.3-21.9-79.2 0L362.3 51.7l97.9 97.9 30.1-30.1c21.9-21.9 21.9-57.3 0-79.2L471.6 21.7zm-299.2 220c-6.1 6.1-10.8 13.6-13.5 21.9l-29.6 88.8c-2.9 8.6-.6 18.1 5.8 24.6s15.9 8.7 24.6 5.8l88.8-29.6c8.2-2.7 15.7-7.4 21.9-13.5L437.7 172.3 339.7 74.3 172.4 241.7zM96 64C43 64 0 107 0 160V416c0 53 43 96 96 96H352c53 0 96-43 96-96V320c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H96z" fill="white"/>
            </svg>
          </div>
           <div id="deleteProyect" data-id="${proyectos.id}">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="27" height="30">
                <path d="M170.5 51.6L151.5 80h145l-19-28.4c-1.5-2.2-4-3.6-6.7-3.6H177.1c-2.7 0-5.2 1.3-6.7 3.6zm147-26.6L354.2 80H368h48 8c13.3 0 24 10.7 24 24s-10.7 24-24 24h-8V432c0 44.2-35.8 80-80 80H112c-44.2 0-80-35.8-80-80V128H24c-13.3 0-24-10.7-24-24S10.7 80 24 80h8H80 93.8l36.7-55.1C140.9 9.4 158.4 0 177.1 0h93.7c18.7 0 36.2 9.4 46.6 24.9zM80 128V432c0 17.7 14.3 32 32 32H336c17.7 0 32-14.3 32-32V128H80zm80 64V400c0 8.8-7.2 16-16 16s-16-7.2-16-16V192c0-8.8 7.2-16 16-16s16 7.2 16 16zm80 0V400c0 8.8-7.2 16-16 16s-16-7.2-16-16V192c0-8.8 7.2-16 16-16s16 7.2 16 16zm80 0V400c0 8.8-7.2 16-16 16s-16-7.2-16-16V192c0-8.8 7.2-16 16-16s16 7.2 16 16z" fill="white"/>
                </svg>
            </div>
       </div>
    <div class="img mb-3" ><img src='/proyects-img/${proyectos.img}' alt="" style="object-fit: cover;"></div>
    <div class="modelProyects">
  <div class="title mb-2 font-weight-bolder fs-4" style="height:auto;min-height: 75px;">${proyectos.titulo}</div>
  <div class="descripcion">${proyectos.descripcion}</div>
  <div class="portaSocial">
<div>
    <a href="${proyectos.github}" target="_blank"> <div class="github mt-4 me-3"><i class="bx bxl-github"><span>Github</span></i></div></a>
</div>
<div>
   <a href="${proyectos.enlinea}" target="_blank"> <div class="View-Online mt-4"><i class="bx bx-world"><span>Online</span></i></div></a>  
</div>
 
  </div>
  </div>
  <div class="madeWith">
  <div class="ps-2"><p> Hecho con:</p></div>
  <div class="madeWithIcon m-2">
  <div class="madeWithValue-${proyectos.id} me-2 d-flex"></div>
  
    </div>
  </div>
  </div>
  </div>
          
          `
          proyectsosall?.appendChild(article)
          
//////////////////////////////////////////////////////////////////////////////
//////////////////   madeWithValue de la home     

                const valuemadewith = document.querySelector(`.madeWithValue-${proyectos.id}`) as HTMLElement
              
                valuemadewith.textContent = "";
                const iconmade = proyects.find(madewith => madewith.id ===proyectos.id)?.madewith.split(',')
                const iconmadewith = iconmade 
                
                const ul = document.createElement('ul') 
                ul.className = 'custom-list';
                ul.id = `madeWithValue-${proyectos.id}` ;
                ul.style.display ='flex'
                ul.style.flexWrap = 'wrap';
                
                if(iconmadewith)
                  {
                iconmadewith.forEach((value:string) => {
                  const li = document.createElement('li');
                  li.className = value;
                  li.textContent = value;
                  ul.appendChild(li);
                  valuemadewith.appendChild(ul);
                });  
              }
              

////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////Fin Cuarta parte///////////////////////////////

          

//////////////////////////////////////////////////////////////////////////
///Editar  proyectos

            
         const usuarioEncontrado = usuarios.find((usuario:any) => usuario.token === sessionStorage.session);
     
         if (usuarioEncontrado && usuarioEncontrado.token === sessionStorage.session && usuarioEncontrado.nombre ===sessionStorage.nombre ) {
   
                const setProyect = document.querySelectorAll('#setProyect');
                setProyect.forEach((modify:any) => {
                  modify.style.display = 'flex';
                });

                const editproyect = document.querySelectorAll('#editProyect');
                editproyect.forEach((elemento:any) => {
                const idNow = elemento.getAttribute('data-id');
                elemento.className = 'editProyect';
                elemento.setAttribute('data-bs-toggle', 'modal');
                elemento.setAttribute('data-bs-target', `#proyectsModal-${idNow}`);
  
                  const modal = document.createElement('div');
                  modal.id = `proyectsModal-${idNow}`;
                  modal.className = 'modal fade  mt-5 pb-5';
                  modal.setAttribute('data-bs-backdrop', 'static');
                  modal.setAttribute('data-bs-keyboard', 'false');
 
  
  // Sanitizar el contenido antes de insertarlo para evitar XSS
               const safeTitulo = sanitizeHTML(proyectos.titulo);
               const safeDescripcion = sanitizeHTML(proyectos.descripcion);
               const safeGithub = sanitizeHTML(proyectos.github);
               const safeEnlinea = sanitizeHTML(proyectos.enlinea);

               modal.innerHTML = `
        <div class="modal-dialog">
        <div class="modal-content">
        <div class="modal-header">
          <h1 class="font-weight-bold">Mis proyectos</h1>
          <button class="btn-close" data-bs-dismiss="modal"></button>
        </div>
        <div class="modal-body" style="display: flex; flex-wrap: wrap; justify-content: center;">
          <div class="col">
            <div class="miProyect">
              <div class="proyectsEdit">
                <form id="uploadfileimg-${idNow}" action="/upload-img-proyect" method="post" enctype="multipart/form-data" style="display:none;">
                  <input type="file" name="archivo" id="fileimg-${idNow}">
                </form>
                <div class="editImage" id="editImage-${idNow}">
                  <!-- SVG contenido -->
                   <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="25" height="30">
                  <path d="M0 96C0 60.7 28.7 32 64 32H448c35.3 0 64 28.7 64 64V416c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V96zM323.8 202.5c-4.5-6.6-11.9-10.5-19.8-10.5s-15.4 3.9-19.8 10.5l-87 127.6L170.7 297c-4.6-5.7-11.5-9-18.7-9s-14.2 3.3-18.7 9l-64 80c-5.8 7.2-6.9 17.1-2.9 25.4s12.4 13.6 21.6 13.6h96 32H424c8.9 0 17.1-4.9 21.2-12.8s3.6-17.4-1.4-24.7l-120-176zM112 192a48 48 0 1 0 0-96 48 48 0 1 0 0 96z" fill="white"/></svg>
     
                </div>
                <div id="progressUpdateImg-${idNow}" class="ms-3"></div>
                <span class="ms-2 mb-2" style="font-style:italic; color:#754ef9;" id="spanEditImage-${idNow}"></span>
                <div class="img mb-3">
                  <img src='/proyects-img/${proyectos.img}' alt="" id="imgeditproyect-${idNow}" style="object-fit: cover;">
                </div>
                <div class="modelProyectsEdit">
                  <div class="title mb-1 font-weight-bolder">
                    <h3>Titulo:</h3>
                    <textarea id="titleditproyect-${idNow}">${safeTitulo}</textarea>
                    <span id="wordCountitle-${idNow}" style="font-size:15px;color:#754ef9;"></span>
                  </div>
                  <div class="descripcionEdit pb-2">
                    <h3>Descripcion:</h3>
                    <textarea id="textDescrArea-${idNow}" rows="9">${safeDescripcion}</textarea>
                    <span id="wordCountDescr-${idNow}" style="font-size:15px;color:#754ef9;font-weight:bold;"></span>
                  </div>
                  <div class="portaSocialEdit">
                    <div>
                      <div class="githubEdit mt-2 me-3 w-100">
                        <i class="bx bxl-github"><span>Github:</span></i>
                        <textarea name="updateGithub" id="updateGithub-${idNow}" rows="1">${safeGithub}</textarea>
                      </div>
                      <span id="wordCountGit-${idNow}" style="font-size:15px;color:#754ef9;font-weight:bold;margin-top:15px;"></span>
                    </div>
                    <div>
                      <div class="View-Online-Edit mt-4 w-100">
                        <i class="bx bx-world"><span>Online</span></i>
                        <textarea id="updateEnlinea-${idNow}" rows="1">${safeEnlinea}</textarea>
                      </div>
                      <span id="wordCountOnline-${idNow}" style="font-size:15px;color:#754ef9;font-weight:bold;margin-top:10px;margin-bottom:10px;"></span>
                    </div>
                  </div>
                </div>
                <div class="ps-2 fs-5 m-3">
                  <p style="margin:0;margin-top: 5px;">Tecnologías desarrolladas con:</p>
                </div>
                <div class="madeWithIconEdit-${idNow} ms-4 mb-4" id="madeWithIconEdit-${idNow}"></div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button class="btn btn-primary" id="updateproyectid-${idNow}">Actualizar</button>
        </div>
      </div>
    </div>
  `;

  // Asegúrate de que `proyectsosall` esté definido
  if (mainModal) {
    mainModal.appendChild(modal);
  }

  // Función de sanitización (ejemplo, usa una biblioteca en producción)
function sanitizeHTML(html: string): string {
  // Implementar sanitización real aquí
  return html.replace(/</g, '&lt;').replace(/>/g, '&gt;');
}
////////////////////////////////////////hasta aca llego la verificacion de seguridad 1



/////////////////////Quita parte///////////////////////////////////////////////////7
 //////////////////////////////////////////////////////
 /////////////////////////////////////////////Parte de limit word
 //// titulo

// Función para escapar texto y prevenir ataques XSS
function escapeHTML(text: string): string {
  const element = document.createElement('div');
  element.innerText = text;
  return element.innerHTML;
}

// Limitar caracteres y prevenir XSS en el título
const wordLimittitle = 70;
const titleditproyect = document.getElementById(`titleditproyect-${idNow}`) as any;
const wordCountitle = document.getElementById(`wordCountitle-${idNow}`) as any;

titleditproyect.addEventListener('input', () => {
  let text = escapeHTML(titleditproyect.value); // Escapa el texto de la entrada
  let charCount = text.length;

  if (charCount > wordLimittitle) {
    titleditproyect.value = text.slice(0, wordLimittitle);
    charCount = wordLimittitle;
  }

  wordCountitle.textContent = `${charCount}/${wordLimittitle} Caracteres`;
});

//// Limitar caracteres y prevenir XSS en la descripción
const wordLimitDescr = 300;
const textDescrArea = document.getElementById(`textDescrArea-${idNow}`) as any;
const wordCountDescr = document.getElementById(`wordCountDescr-${idNow}`) as any;

textDescrArea.addEventListener('input', () => {
  let text = escapeHTML(textDescrArea.value); // Escapa el texto de la entrada
  let charCount = text.length;

  if (charCount > wordLimitDescr) {
    textDescrArea.value = text.slice(0, wordLimitDescr);
    charCount = wordLimitDescr;
  }

  wordCountDescr.textContent = `${charCount}/${wordLimitDescr} Caracteres`;
});

//// Limitar caracteres y prevenir XSS en el GitHub
const wordLimitGit = 35;
const updateGithub = document.getElementById(`updateGithub-${idNow}`) as any;
const wordCountGit = document.getElementById(`wordCountGit-${idNow}`) as any;

updateGithub.addEventListener('input', () => {
  let text = escapeHTML(updateGithub.value); // Escapa el texto de la entrada
  let charCount = text.length;

  if (charCount > wordLimitGit) {
    updateGithub.value = text.slice(0, wordLimitGit);
    charCount = wordLimitGit;
  }

  wordCountGit.textContent = `${charCount}/${wordLimitGit} Caracteres`;
});

//// Limitar caracteres y prevenir XSS en el Online
const wordLimitOnline = 35;
const updateEnlinea = document.getElementById(`updateEnlinea-${idNow}`) as any;
const wordCountOnline = document.getElementById(`wordCountOnline-${idNow}`) as any;

updateEnlinea.addEventListener('input', () => {
  let text = escapeHTML(updateEnlinea.value); // Escapa el texto de la entrada
  let charCount = text.length;

  if (charCount > wordLimitOnline) {
    updateEnlinea.value = text.slice(0, wordLimitOnline);
    charCount = wordLimitOnline;
  }

  wordCountOnline.textContent = `${charCount}/${wordLimitOnline} Caracteres`;
});

//// Reutilizar código y prevenir XSS en la edición de tecnologías
const editWithClick = document.querySelectorAll(`#madeWithValue-${idNow} li`);
const madeWithIconEdit = document.querySelector(`.madeWithIconEdit-${idNow}`);

if (madeWithIconEdit) {
  madeWithIconEdit.innerHTML = '';
  const addedElements = new Set();

  tecnologias.forEach((element) => {
    const textContent = escapeHTML(element.nombre?.trim() || ''); // Escapa el nombre de la tecnología

    if (textContent && !addedElements.has(textContent)) {
      const input = document.createElement('input');
      input.style.marginRight = '5px';
      input.type = 'checkbox';
      input.id = textContent;
      input.name = textContent;
      input.value = textContent;

      const label = document.createElement('label');
      label.textContent = textContent;
      label.setAttribute('for', input.id);

      let encontrado = false;
      editWithClick.forEach((liElement: any) => {
        if (liElement.textContent?.trim() === textContent && !addedElements.has(textContent)) {
          encontrado = true;
          addedElements.add(textContent);
        }
      });

      if (encontrado) {
        input.checked = true;
      }

      madeWithIconEdit.appendChild(input);
      madeWithIconEdit.appendChild(label);
      madeWithIconEdit.appendChild(document.createElement('br'));
      addedElements.add(textContent);
    }
  });
}

/////////////////////////////////////////////////////////////

///////////////////////////////////////Fin quinta parte/////////////////////////////
  
     ////////////////////////////////////////////////////////////////////////////////////
     //////////// Carga imagen de proyecto

     //////////////////////////////////// Seis Partes/////////////////////////////////

     const cargarimgElements = document.querySelectorAll(`[id^='editImage-']`);

     cargarimgElements.forEach((cargarimg:any) => {
       const idNow = cargarimg.id.split('-')[1];
       const cargafileimg = document.getElementById(`fileimg-${idNow}`) as HTMLInputElement;

       
       // Limitar la selección de archivos solo a imágenes
       cargafileimg.setAttribute('accept', 'image/*');
     
       if (!cargarimg.dataset.clickEventAttached) {
         cargarimg.dataset.clickEventAttached = 'true';
     
         const handleClick = () => {
           cargafileimg.click();
         };
     
         cargarimg.addEventListener('click', handleClick);
       }
     
       if (!cargafileimg.dataset.changeEventAttached) {
         cargafileimg.dataset.changeEventAttached = 'true';
     
         const handleChange = () => {
           const spanEditImage = document.getElementById(`spanEditImage-${idNow}`) as HTMLElement;
           const imgeditproyect = document.getElementById(`imgeditproyect-${idNow}`) as HTMLInputElement;
           const progressUpdateImg = document.getElementById(`progressUpdateImg-${idNow}`) as HTMLElement;
           
           // Crear elemento <progress> para mostrar el progreso
           let progress = document.createElement('progress');
           progressUpdateImg.appendChild(progress);
     
           if (cargafileimg.files && cargafileimg.files.length > 0) {
             const file = cargafileimg.files[0];
             
             // Validar el tipo de archivo
             const validImageTypes = ['image/jpeg', 'image/png', 'image/gif', 'image/webp'];
             if (!validImageTypes.includes(file.type)) {
               spanEditImage.textContent = 'El archivo seleccionado no es una imagen válida';
               return;
             }
             
             // Validar el tamaño del archivo
             const maxSizeInMB = 3;
             if (file.size > maxSizeInMB * 1024 * 1024) {
               spanEditImage.textContent = `El archivo seleccionado excede el límite de ${maxSizeInMB} MB`;
               return;
             }
     
             spanEditImage.textContent = `Cambiando imagen... Archivo seleccionado: ${file.name}`;
             console.log('Nueva imagen generada', file);
     
             // Crear una URL para la imagen seleccionada
             const reader = new FileReader();
     
             reader.onload = (e: ProgressEvent<FileReader> ) => {
              if (e.target) {
                imgeditproyect.src = e.target.result as string;
              }
             };
     
             reader.readAsDataURL(file);
     
             // Mostrar el progreso de carga de la imagen
             let xhr = new XMLHttpRequest();
             xhr.responseType = "blob";
             xhr.open("get", URL.createObjectURL(file));
             xhr.addEventListener('load', () => {
               if (xhr.status == 200) {
                 let imagen_blob = xhr.response;
                 let url = URL.createObjectURL(imagen_blob);
                 imgeditproyect.src = url;
                 progress.style.display = 'none';
               }
             });
     
             xhr.addEventListener('progress', (e) => {
               if (e.lengthComputable) {
                 let porcentaje = (e.loaded * 100) / e.total;
                 progress.value = porcentaje;
               }
             });
     
             xhr.send();
           } else {

             spanEditImage.textContent = 'No se seleccionó ningún archivo';
           }
         };
     
         cargafileimg.addEventListener('change', handleChange);
       }
     });
     
     // Configuración para cargar el archivo
     
  cargarimgElements.forEach((cargarimg: any) => {
  const idNow = cargarimg.id.split('-')[1];
  const updateproyectid = document.getElementById(`updateproyectid-${idNow}`) as HTMLElement;

  if (!updateproyectid.dataset.clickEventAttached) {
    updateproyectid.dataset.clickEventAttached = 'true';

    updateproyectid?.addEventListener('click', () => {
      const fileInput = document.getElementById(`fileimg-${idNow}`) as HTMLInputElement;

      if (fileInput && fileInput.files && fileInput.files.length >= 0) {/*la opcion igual = a 0 es solo para la version editar proyecto no para agregar proyecto */
        
        const formData = new FormData();
        const files = fileInput.files[0]
        // Toco hacerlo así para que el compilador webpack no se reinicie
         formData.append('file', files );
         formData.append('filename',`proyecto-${idNow}` ); // Añade el nombre del archivo deseado como parte del formulario


    //////////////////////////////////////////////////////////////////////////
    ////////////////////////////

        fetch('https://lfgarcia.com/upload-img-proyects', {
          method: 'POST',
          body: formData
        })
        .then(response => response.text())
        .then(data => {
          const titulo = document.getElementById(`titleditproyect-${idNow}`) as HTMLInputElement
          const descripcion = document.getElementById(`textDescrArea-${idNow}`) as HTMLInputElement
          const github = document.getElementById(`updateGithub-${idNow}`) as HTMLInputElement
          const enlinea = document.getElementById(`updateEnlinea-${idNow}`) as HTMLInputElement
        
          const checkboxes = document.querySelectorAll(`#madeWithIconEdit-${idNow} input[type="checkbox"]`) as NodeListOf<HTMLInputElement>;
        // Crear un array con los valores de los checkboxes que están marcados
        // Función para sanear el texto e impedir ataques XSS
     function sanitizeText(text: string): string {
      const tempDiv = document.createElement('div');
      tempDiv.textContent = text;
      return tempDiv.innerHTML;
      }
         const checkedValues = Array.from(checkboxes)
        .filter((checkbox:HTMLInputElement) => checkbox.checked)
           .map((checkbox:HTMLInputElement) => sanitizeText(checkbox.value));
           // Convertir el array en una cadena de texto con el formato deseado
          const formattedString = checkedValues.join(', ') + ' ';
          // Puedes usar la cadena de texto formattedString según tus necesidades
          
    ///////////////////////////////////////////////////////////////////
    ///////////////////////// Peticion validacion
     
             if(titulo.value.length===0){
             alert('Valor Titulo esta vacio')
             }
             else if(descripcion.value.length===0){
              alert('Valor Descripcion esta vacio')
             }
             else if(github.value.length===0){
              alert('Valor Github esta vacio')
             }
             else if(enlinea.value.length===0){
              alert('Valor Online esta vacio')
            
             }
             else if(formattedString.length<=1){
              alert('Ningun campo tecnologico esta seleccionado')
             }


             else{

             alert('Informacion se actualizo correctamente');
             requestMain('put',`updateProyecto/${idNow}`,{
             img:`proyecto-${idNow}.png`,
                titulo: sanitizeText(titulo.value),
                descripcion: sanitizeText(descripcion.value),
                github: sanitizeText(github.value),
                enlinea: sanitizeText(enlinea.value),
             madewith:formattedString
          }).then(()=>{
            window.location.reload()
          })
        }
          ///////////////////////////////////////////////////////////////////
          ///////////////////////
          
        })
        .catch(error => {
          console.error('Error al cargar el archivo:', error);
        });

///////////////////////////////////////////////////////////////////////////////////
      } 
      /* Esta opcion se activa en la opcion de agregar post
      else {
              alert('No se seleccionó ningún archivo para cargar.');
            }
      */
    });
  }
});
///////////////////////////////////////////////////////    
                });


///////////////////////////////////////////////Fin sesta parte

          ////////////////////////////////////////////////////////////////
          //Eliminar proyectos
          const deleteproyect = document.querySelectorAll('#deleteProyect');

          deleteproyect.forEach((elemento: any) => {
            if (!elemento.classList.contains('event-added')) {
              elemento.className = 'deleteProyect';
              elemento.style.cursor = 'pointer';
          
              elemento.addEventListener('click', () => {
                // Confirmación de la acción de eliminación
                if (confirm('¿Estás seguro de que quieres borrar este proyecto?')) {
                  // Validación y escape del atributo data-id
                  const idDetele = elemento.getAttribute('data-id');
                  
                  if(confirm('¿Estás seguro de que quieres borrar este proyecto?')){
                    const idDetele = elemento.getAttribute('data-id') as Number// Suponiendo que sea un ID de MongoDB o similar
                    requestMain('put', `deleteProyecto/${idDetele}`, {
                      id: idDetele
                    }).then(() => {
                      window.location.reload();
                    }).catch((error) => {
                      console.error('Error al eliminar el proyecto:', error);
                    });
                  } else {
                    console.error('ID no válido:', idDetele);
                  }
                }
              });
            }
            elemento.classList.add('event-added');
          });

              }
                   

              ///////Fin eliminar proyectos
          ///////////////////////////////////////////////////////////////////////

    
              const section = document.querySelectorAll('section');
          
              function activateVisibleSection() {
                let currentScroll = window.scrollY;
          
                section.forEach(sec => {
                  let offset = sec.offsetTop - 150;
                  let height = sec.offsetHeight;
                  let id = sec.getAttribute('id');
          
                  if (currentScroll >= offset && currentScroll < offset + height) {
                    document.querySelectorAll('a').forEach(enlace => {
                      if (enlace.pathname.replace('/', '') === id) {
                        enlace.classList.add('activar');
                      } else {
                        enlace.classList.remove('activar');
                      }
                    });
                  }
                });
              }
          
              function scrollToSection(event:any) {
           
                let targetId = event.currentTarget.getAttribute('href').replace('#', '');
                let targetSection = document.getElementById(targetId);
          
                if (targetSection) {
                  let offsetTop = targetSection.offsetTop - 100;
                  window.scrollTo({
                    top: offsetTop,
                    behavior: 'smooth'
                  });
                }
              }
              document.querySelectorAll('a').forEach(enlace => {
                enlace.addEventListener('click', scrollToSection);
              });    
              window.onscroll = () => {
                activateVisibleSection();
              };
              activateVisibleSection();
          
        });
        }
       })
    } catch (error) {
        console.error('Error al obtener el perfil:', error);
    }
  }
  obtenerPerfil()

  ///////////////////////////////////////////////////////////////////////////////////7
    //Footer
  renderFooter('/pages/home/footer.hbs', {container: {undefined} }).then(()=>{
      document.querySelector('.footer-iconTop')?.addEventListener('click', (e) => {
        e.preventDefault();
        /////////////////////////////////////////////////////////////

        // Obtener la ruta sin la barra inicial
        // Buscar el elemento con el ID correspondiente al pathname
        const targetElement = document.querySelector('.overlay');
    
        // Si se encuentra el elemento, desplazar hasta él
        if (targetElement) {
            targetElement.scrollIntoView({
                behavior: 'smooth' // Desplazamiento suave
            });
        }
    });
  })

//////////////////////////////////////////////////////////////////
////Fin header
//////////////////////////////////////////////
// Obtener todas las secciones 
/////////////////////////////

const rootElement = document.getElementById('root');
if (rootElement) {
    createRoot(rootElement).render(<App />); 
  } else {
    console.error("Elemento 'root' no encontrado en el DOM.");
  }
export default rootElement
